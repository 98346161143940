<template>
  <!-- start: left-col -->
  <div class="py-6 px-6 mt-2 w-full h-full bg-white rounded shadow ">
    <!-- start: title-section -->
    <div class="flex justify-between items-center mb-6">
      <div :class="typographyOne">{{ title }}</div>
      <div v-if="editButton">
        <oto-edit-button
          v-if="editPermissionStatus"
          variant="gray"
          @click="handleClicking()"
        />
      </div>
    </div>
    <!-- end: title-section -->
    <!-- start: footer-section -->
    <div class="info-grid-subheader" :class="subheaderClass">
      <slot
        name="subheader"
        :typographyOne="typographyOne"
        :typographyTwo="typographyTwo"
        :typographyThree="typographyThree"
      ></slot>
    </div>
    <!-- end: footer-section -->

    <!-- start: avatar-section -->
    <template v-if="avatar">
      <display-avatar :avatar="avatar" class="my-6" />
    </template>
    <!-- end: avatar-section -->

    <!-- start: details-section -->
    <div class="grid" :class="gridClass" v-if="data">
      <div
        class="grid grid-cols-2 my-3"
        v-for="(item, index) in data"
        :key="index"
      >
        <div :class="typographyTwo">
          {{ item.text }}
        </div>

        <!-- <div
          v-if="item.id"
          :class="typographyThree"
          :style="item.color ? `color: ${item.color}` : ''"
        >
          
        </div> -->
        <div
          v-if="item.key === 'vehicleQRCodeURL'"
          class="flex"
          :class="typographyThree"
        >
          {{ item.value }}
          <div class="cursor-pointer " @click="copyToClipBoardText(item.value)">
            <i class="ml-3 far fa-copy"></i>
          </div>
        </div>
        <div
          v-else-if="
            item.key === 'currentLatLong' || item.key === 'currentAddressCoords'
          "
          class="flex"
          :class="typographyThree"
        >
          <span :style="item.color ? `color: ${item.color}` : ''">{{
            item.value
          }}</span>
          <div
            class="cursor-pointer "
            @click="
              copyToClipBoardText(
                `https://www.google.com/maps/search/?api=1&query=${item.value}`
              )
            "
          >
            <i class="ml-3 far fa-copy"></i>
          </div>
        </div>
        <div
          v-else-if="item.key === 'phone_number'"
          class="flex"
          :class="typographyThree"
        >
          {{ item.value }}
          <div
            v-if="editPermissionStatus"
            class="cursor-pointer pl-3"
            @click="handleKeyUpdate(item.key)"
          >
            <svg
              class="w-4 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              ></path>
            </svg>
          </div>
        </div>
        <div
          v-else-if="item.key === 'email'"
          class="flex"
          :class="typographyThree"
        >
          {{ item.value }}
          <div
            v-if="editPermissionStatus"
            class="cursor-pointer pl-3"
            @click="handleKeyUpdate(item.key)"
          >
            <svg
              class="w-4 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              ></path>
            </svg>
          </div>
        </div>

        <div
          v-else
          :class="typographyThree"
          :style="item.color ? `color: ${item.color}` : ''"
        >
          <router-link
            v-if="item.text === 'Assigned Fleet' || item.key === 'currentFleet'"
            :to="{ name: 'ViewFleetProfile', params: { id: item.id } }"
            :class="`text-blue-600`"
            target="_blank"
          >
            {{ item.value }}
          </router-link>

          <router-link
            v-else-if="item.text === 'Assigned Geofence'"
            :to="{ name: 'ViewServiceArea', params: { id: item.id } }"
            :class="`text-blue-600`"
            target="_blank"
          >
            {{ item.value }}
          </router-link>
          <span v-else>{{ item.value }}</span>
        </div>
      </div>
    </div>
    <!-- start: details-section -->

    <!-- start: footer-section -->
    <div class="info-grid-footer" :class="footerClass">
      <slot
        name="footer"
        :typographyOne="typographyOne"
        :typographyTwo="typographyTwo"
        :typographyThree="typographyThree"
      ></slot>
    </div>
    <!-- end: footer-section -->
  </div>
  <!-- end: left-col -->
</template>

<script>
import DisplayAvatar from '@/components/ui/DisplayAvatar'
import OtoEditButton from '@/components/ui/OtoEditButton'
import CopyToClipboardMixin from '@/mixins/copyToClipboardMixin'
export default {
  name: 'InfoGrid',
  mixins: [CopyToClipboardMixin],
  components: { DisplayAvatar, OtoEditButton },
  props: {
    avatar: { required: false, type: String },
    title: { required: false, type: String, default: 'Title' },
    data: { required: false, type: Array },
    editButton: { required: false, type: Boolean, default: true },
    gridClass: { required: false, type: String, default: 'grid-cols-2' },
    footerClass: { required: false, type: String, default: '' },
    subheaderClass: { required: false, type: String, default: '' },
    type: { required: false },
    editPermissionStatus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      // title
      typographyOne: 'font-semibold uppercase text-oDark text-xl',
      // subtitle
      typographyTwo: 'font-semibold text-14px text-oDark',
      // subtitle->value
      typographyThree: 'font-medium text-14px text-gray-500',
    }
  },
  mounted() {},
  methods: {
    handleClicking() {
      console.log('type = ', this.type)
      this.$emit('edit', this.type)
    },
    copyToClipBoardText(url) {
      // using mixin
      this.copyContentToClipboard(url, {
        title: 'Copied',
        text: 'Text has been copied to your clipboard',
      })
    },
    handleKeyUpdate(key) {
      this.$emit('single-edit', key)
    },
  },
}
</script>

<style lang="sass">
// .edit-button
//     width: 96px
//     height: 42px
//     padding: 4px 23px 5px 12px
//     border-radius: 30px
//     background-color: #f3f3f3
//     cursor: pointer
</style>
